///
/// Nav.jsx
///

import Comment from "./Comment"
import { Component } from "react";
import Login from "./Login";
import Subscription from "./Subscription";
import { ref as Toast } from "./Toasts";

class Nav extends Component {
  constructor(props) {
    super();
    this.state = {
      subscribe: false,
      login: false,
      page: "Home",
    };

    this.selectPage = props.selectPage;

    this.home = this.home.bind(this);
    this.prospect = this.prospect.bind(this);
    this.student = this.student.bind(this);
    this.subscriber = this.subscriber.bind(this);
    this.investor = this.investor.bind(this);
    this.contributors = this.contributors.bind(this);
    this.events = this.events.bind(this);
    this.about = this.about.bind(this);
    // this.subscribe = this.subscribe.bind(this);
    this.login = this.login.bind(this);
    this.hide = this.hide.bind(this);
  }

  home = () => {
    const state = { page: "Home" };
    this.setState(state);
    this.selectPage(0);
  };

  prospect = () => {
    const state = { page: "Prospect", login: false, subscribe: true }
    this.setState(state);
    this.selectPage(0);
  };

  student = () => {
    const state = { page: "Student", login: true, subscribe: false }
    this.setState(state);
    this.selectPage(0);
  };

  subscriber = () => {
    const state = { page: "Subscriber", login: true, subscribe: false }
    this.setState(state);
    this.selectPage(0);
  };

  investor = () => {
    const state = { page: "Investor", login: true, subscribe: false }
    this.setState(state);
    this.selectPage(0);
  };

  events = () => {
    this.setState({ page: "Events" });
    this.selectPage(1);
  };

  contributors = () => {
    this.setState({ page: "Contributors" });
    this.selectPage(2);
  };

  about = () => {
    this.setState({ page: "About" });
    this.selectPage(3);
  };

  subscribe = () => {
    this.setState({ subscribe: false, login: false });
    Toast.current.addSuccess("Thank you for subscribing");
  };

  login = () => {
    this.setState({ subscribe: false, login: false });
    Toast.current.addError("Invalid credentials");
  };

  hide = () => {
    this.setState({ subscribe: false, login: false });
  };

  style = "btn btn-secondary";

  render() {
    const menu = (
      <div>
        <div className="btn-group menu" role="group" aria-label="Basic example">
          <button type="button" className={`${this.style}`} onClick={this.home}>
            Home
          </button>
          <button disabled={true} className={`${this.style}`}>
            |
          </button>
          <Comment>
          <button id="subscribe" type="button" className={`${this.style}`} onClick={this.prospect}>
            Subscribe!
          </button>
          <button disabled={true} className={`${this.style}`}>
            |
          </button>
          <button type="button" className={`${this.style}`} onClick={this.student}>
            Student Portal
          </button>
          <button disabled={true} className={`${this.style}`}>
            |
          </button>
          <button type="button" className={`${this.style}`} onClick={this.subscriber}>
            Subscriber Portal
          </button>
          <button disabled={true} className={`${this.style}`}>
            |
          </button>
          <button type="button" className={`${this.style}`} onClick={this.investor}>
            Investor Portal
          </button>
          <button disabled={true} className={`${this.style}`}>
            |
          </button>
          </Comment>
          <button type="button" className={`${this.style}`} onClick={this.contributors}>
            Contributors
          </button>
          <button disabled={true} className={`${this.style}`}>
            |
          </button>
          <button type="button" className={`${this.style}`} onClick={this.events}>
            Events
          </button>
          <Comment>
          <button disabled={true} className={`${this.style}`}>
            |
          </button>
          <button type="button" className={`${this.style}`} onClick={this.about}>
            About
          </button>
          </Comment>
        </div>
        <Login show={this.state.login} handleSubmit={this.login} handleClose={this.hide} kind={this.state.page} />
        <Subscription show={this.state.subscribe} /* handleSubmit={this.subscribe} */ handleClose={this.hide}/>
    </div>
    );
    return menu;
  }
}

export default Nav;
