///
/// Body
///

import About from "./About";
import Contributors from "./Contributors";
import Events from "./Events";
import Splash from "./Splash";

const content = [<Splash />, <Events />, <Contributors />, <About />];

function Body({ page }) {
  const index = page || 0;
  const body = <div className="Main-body">{content[index]}</div>;
  return body;
}

export default Body;
