///
/// Footer.jsx
///

import Quote from "./Quote";

const footer = (
  <footer className="Main-footer">
    <Quote />
    <p>
      <b>Copyright &copy; Lakebolt&trade; Research, LLC - version 20231001</b>
    </p>
  </footer>
);

const Footer = () => footer;

export default Footer;
