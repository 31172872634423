///
/// index.js
///

// Infrastructure
import React from "react";
import ReactClientDOM from "react-dom/client";

// Web vitals
import reportWebVitals from "./reportWebVitals";

// ToastProvider
import { ToastProvider } from "react-toast-notifications";

// Main
import "./index.css";
import Main from "./Main";

// Render Main
const domRoot = document.getElementById("root");
const root = ReactClientDOM.createRoot(domRoot);

const fastMain = (
  <ToastProvider placement="bottom-right">
    <Main />
  </ToastProvider>
);

const checkedMain = <React.StrictMode>{fastMain}</React.StrictMode>;

const strict = false;

root.render(strict ? checkedMain : fastMain);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
