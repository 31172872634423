///
/// Events.jsx
///

import Table from "react-bootstrap/Table";

import "./Main.css"

const events = [
  { name: "IEEE ITPC 2015", loc: "NJ", date: "March 2015" },
  { name: "IEEE ITPC 2016", loc: "NJ", date: "March 2016" },
  { name: "AMW 2016 Poster Presentation", loc: "Panama", date: "June 2016" },
  { name: "Lambda Days 2017", loc: "Kraków", date: "February 2017" },
  { name: "IEEE ITPC 2017", loc: "NJ", date: "March 2017" },
  { name: "Compose 2017", loc: "NY", date: "May 2017" },
  {
    name: "ACM SPLASH 2017 - LIVE Workshop",
    loc: "Vancouver",
    date: "October 2017",
  },
  {
    name: "ACM/IEEE Princeton Chapters Meeting",
    loc: "NJ",
    date: "March 2018",
  },
  { name: "IEEE ITPC 2018", loc: "NJ", date: "March 2018" },
  { name: "OPLSS 2018 Student Presentation", loc: "OR", date: "July 2018" },
  { name: "IEEE ITPC 2019", loc: "NJ", date: "March 2019" },
  { name: "Wharton Entrepreneurial Showcase", loc: "PA", date: "May 2019" },
  { name: "Lambda Days 2020", loc: "Kraków", date: "February 2020" },
  { name: "ICICT 2020 Demo Presentation", loc: "CA", date: "March 2020" },
  {
    name: "ICICT 2021 Paper Presentation",
    loc: "HI",
    date: "March 2021",
  },
  { name: "IEEE ITPC 2021", loc: "NJ", date: "March 2021" },
  { name: "IEEE ITPC 2022", loc: "NJ", date: "March 2022" },
  { name: "OPLSS 2022 Student Presentation", loc: "OR", date: "June 2022" },
  { name: "TFPIE 2023", loc: "MA", date: "January 2023" },
  { name: "TFP 2023", loc: "MA", date: "January 2023" },
  { name: "IEEE ISEC 2023", loc: "MD", date: "March 2023" },
  { name: "IEEE ITPC 2023", loc: "NJ", date: "March 2023" },
  {
    name: "Academia Nacional de Ingeniería",
    loc: "Venezuela",
    date: "May 2023",
  },
  { name: "IoT Online 2023", loc: "Online", date: "November 2023" },
  { name: "TFP 2024", loc: "MA", date: "January 2024" },
  { name: "IEEE ITPC 2024", loc: "NJ", date: "March 2024" }
];

const stupid_header_fixed = false;

const header = (
  <theader>
    <tr>
      <th>Event</th>
      <th>Location</th>
      <th>Date</th>
    </tr>
  </theader>
);

const row = item => (
  <tr key={item.name}>
    <td>{item.name}</td>
    <td>{item.loc}</td>
    <td>{item.date}</td>
  </tr>
);

const body = <tbody>{events.map(row)}</tbody>;

const table = (
  <div className="Main-events">
    <h6>ZenSheet&trade; Presentations at Conferences and Seminars</h6>
    <Table striped bordered hover variant="dark">
      {stupid_header_fixed ? header : null}
      {body}
    </Table>
  </div>
);

function Events() {
  return table;
}

export default Events;
