///
/// Login
///

import Modal from "./Modal";

function Login(props) {
  const { show, handleSubmit, handleClose, kind } = props;

  const emailField = (
    <div className="form-group">
      <label htmlFor="input-email">Email</label>
      <input
        type="email"
        className="form-control"
        id="input-email"
        autoComplete="current-email"
        aria-describedby="email-help"
        placeholder="Email address"
      />
    </div>
  );

  const passcodeField = (
    <div className="form-group">
      <label htmlFor="input-passcode">Passcode</label>
      <input
        type="passcode"
        className="form-control"
        id="input-passcode"
        autoComplete="current-passcode"
        placeholder="Passcode from subscription confirmation"
      />
    </div>
  );

  const buttons = (
    <div className="modal-buttons">
      <button
        type="button"
        className="btn btn-success"
        onClick={handleSubmit}
      >
        Submit
      </button>

      <button
        type="button"
        className="btn btn-secondary"
        onClick={handleClose}
      >
        Close
      </button>
    </div>
  );

  const title = `${kind} Login`;
  const login = (
    <Modal className="form-group" show={show}>
      <form>
        <h2> {title} </h2>
        { emailField }
        { passcodeField }
        { buttons }
      </form>
    </Modal>
  );
  return login;
}

export default Login;
