///
/// Toasts: This component only exists to allow oher components to render
/// toasts at any time.
///

import React, { Component } from "react";
import { useToasts } from "react-toast-notifications";

function withToast(Component) {
  return function WrappedComponent(props) {
    return <Component {...props} {...useToasts()} />;
  };
}

export const ref = { current: null };

class Toasts extends Component {
  constructor(props) {
    super(props);

    // This component is unique
    ref.current = this;
  }

  addError(msg) {
    this.props.addToast(msg, { appearance: "error", autoDismiss: true });
  }

  addSuccess(msg) {
    this.props.addToast(msg, { appearance: "success", autoDismiss: true });
  }

  render() {
    return null;
  }
}

export default withToast(Toasts);
