///
/// About.jsx
///

/* eslint-disable no-multi-str */

import nine from "./images/9Bro.png";
import mcs1 from "./images/MC1.png";
import mcs2 from "./images/MC2.png";
import tmxi from "./images/TMX.png";

const img = [nine, mcs1, mcs2, tmxi];

const index = Math.floor(Math.random() * img.length);

const about = (
  <div  className="About" style={{ display: "inline-block", verticalAlign: "top" }}>
    <div className="splash-image transparent" style={{ float: "left" }}>
      <img
        src={img[index]}
        alt="About Lakebolt Research"
      />
    </div>
  </div>
);

const About = () => about;

export default About;
