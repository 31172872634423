///
/// Quote.jsx
///

const quotes = [
  '“Born you were not to live like ignorant brutes but to pursue knowledge and virtue.” - Dante Alighieri',
  '“Logic will get you from A to Z; imagination will get you everywhere.” - Albert Einstein',
  '“Anyone who has never made a mistake has never tried anything new.” - Albert Einstein',
  '“If we knew what it was we were doing, it would not be called research, would it?” - Albert Einstein',
  '“Everything must be made as simple as possible. But not simpler.” - Albert Einstein',
  '“Programs are meant to be read by humans and only incidentally for computers to execute.” - Donald Knuth',
  '“Computer science is no more about computers than astronomy is about telescopes.” - Edsger W. Dijkstra',
  '“Testing can only prove the presence of bugs, not their absence.” - Edsger W. Dijkstra'
];

const index = Math.floor(Math.random() * quotes.length);

const quote = (
  <p>
    {quotes[index]}
  </p>
);

const Quote = () => quote;

export default Quote;
