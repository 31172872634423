///
/// Splash
///

/* eslint-disable no-multi-str */

import cat0 from "./images/Catatumbo-0.jpg";
import cat1 from "./images/Catatumbo-1.jpg";
import cat2 from "./images/Catatumbo-2.jpg";
import cat3 from "./images/Catatumbo-3.jpg";
import cat4 from "./images/Catatumbo-4.jpg";
import cat5 from "./images/Catatumbo-5.jpg";
import cat6 from "./images/Catatumbo-6.jpg";
import cat7 from "./images/Catatumbo-7.jpg";
import cat8 from "./images/Catatumbo-8.jpg";
import cat9 from "./images/Catatumbo-9.jpg";

const cat = [cat0, cat1, cat2, cat3, cat4, cat5, cat6, cat7, cat8, cat9];

const index = Math.floor(Math.random() * cat.length);

const size = { width: 806, height: 620 };
const scale = 1.125;

/*
  'Wikipedia: \
  The Catatumbo lightning (Spanish: Relámpago del Catatumbo) is an \
  atmospheric phenomenon that occurs over the mouth of the Catatumbo River \
  where it empties into Lake Maracaibo in Venezuela. Catatumbo means "House \
  of Thunder" in the language of the indigenous people. It originates from a \
  mass of storm clouds at an altitude of more than 1 km (0.6 mi), and occurs \
  for 140 to 160 nights a year, nine hours per day, and with lightning \
  flashes from 16 to 40 times per minute.';

  const text = "About Lakebolt Research TBD"

      <div
        style={{
          float: "right",
          width: scale * size.width,
          height: scale * size.height,
          padding: "40px 10px 40px 40px",
        }}
      >
        {text}
      </div>
*/

const splash = (
  <div style={{ display: "inline-block", verticalAlign: "top" }}>
    <div className="splash-image transparent" style={{ float: "left" }}>
      <img
        src={cat[index]}
        width={scale * size.width}
        height={scale * size.height}
        alt="Catatumbo Lightning"
      />
    </div>
  </div>
);

const Splash = () => splash;

export default Splash;
