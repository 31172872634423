///
/// Header.jsx
///

const header = (
  <header className="Main-header">
    <h1>Lakebolt Research</h1>
  </header>
);

const Header = () => header;

export default Header;
