///
/// Main.jsx
///

import "bootstrap/dist/css/bootstrap.min.css";
import "./Main.css";

import { useState } from "react";
import Toasts from "./Toasts";

import Header from "./Header";
import Nav from "./Nav";
import Body from "./Body";
import Footer from "./Footer";

function Main() {
  const [page, setPage] = useState(0);
  const selectPage = index => {
    setPage(index);
  };

  return (
    <div className="Main">
      <Header />
      <Nav selectPage={selectPage} />
      <Body page={page} />
      <Toasts />
      <Footer />
    </div>
  );
}

export default Main;
