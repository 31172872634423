///
/// Subscription.jsx
///

import React, { Component } from 'react';
import Modal from "./Modal";

const clear = {
  email: '',
  tier: 'Trial',
  comment: '',
  errorMessage: '',
}

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = clear;
  }

  close = () => {
    this.setState(clear);
    this.props.handleClose();
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  handleCommit = () => {
    const { email, tier, comment } = this.state;

    if (!email || !this.validateEmail(email)) {
      this.setState({ errorMessage: 'Please enter a valid email address.' });
      return;
    }

    if (!tier) {
      this.setState({ errorMessage: 'Please select subscription tier.' });
      return;
    }

    // Prepare the data to send via email
    const emailData = `Email: ${email}    Kind: ${tier}    Comment: ${comment}`;

    // Open email client and let the user send the email
    window.open(`mailto:subscriptions@lakebolt.com?subject=ZenSheet Subscription&body=${emailData}`);

    this.close();
  };

  render() {
    const { email, tier, comment, errorMessage } = this.state;

    const emailField = (
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input className="form-control"

          id="email"
          type="email"
          name="email"
          value={email}
          placeholder="Email address"
          onChange={this.handleInputChange}
          required
          
          autoComplete="current-email"
          aria-describedby="email-help"
        />
      </div>
    );

    const kindField = (
      <div className="form-group">
        <label htmlFor="tier">Subscription Tier</label>
        <select className="form-control"
          id="tier"
          name="tier"
          value={tier}
          onChange={this.handleInputChange}
          required
        >
          <option value="Trial">Trial</option>
          <option value="Free" disabled>Free</option>
          <option value="Basic" disabled>Basic</option>
          <option value="Advanced" disabled>Advanced</option>
        </select>
      </div>
    );

    let prompt =
    "Please state your intended purpose (e.g.: education, research, investment, ...) and reasons for subscribing.\n" +
    "After you press the green \"Subscribe\" button below, a mail window will appear. Just send the email message as is.\n\n" +
    "By subscribing you will join the waiting list for our next ZenSheet™ release.\n" +
    "Only trial subscriptions are available at this time. Trial subcriptions are 100% free.\n" +
    "Lakebolt Research is currently conducting ZenSheet™ trials at universities and schools worldwide.\n\n";

    const commentField = (
      <div className="form-group">
        <label htmlFor="comment">Comment</label>
        <textarea rows={6} className="form-control"
          id="comment"
          name="comment"
          value={comment}
          placeholder={prompt}
          onChange={this.handleInputChange}
          required
        />
      </div>
    );

    const { show } = this.props;

    return (
      <Modal className="form-group" show={show} >
        <h2>Subscription Dialog</h2>

        <div className="error-message">
          {errorMessage}
        </div>

        { emailField } 
        { kindField } 
        { commentField } 

        <div className="modal-buttons">
          <button
            type="button"
            className="btn btn-success"
            onClick={this.handleCommit}
          >
            Subscribe
          </button>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={this.close}
          >
            Cancel
          </button>
        </div>
      </Modal>
    );
  }
}

export default Subscription;
